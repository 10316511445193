import React, { useEffect } from 'react';

const LINKEDIN_PARTNER_ID = '554492';

const initLinkedIn = () => {
  window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
  window._linkedin_data_partner_ids.push(LINKEDIN_PARTNER_ID);

  const script = document.getElementsByTagName('script')[0];
  const tagScript = document.createElement('script');
  tagScript.type = 'text/javascript';
  tagScript.async = true;
  tagScript.src = 'https://snap.licdn.com/li.lms-analytics/insight.min.js';
  script.parentNode.insertBefore(tagScript, script);

  const position = document.querySelector('body');
  position.appendChild(script);
};

const track = () => {
  const src = `https://px.ads.linkedin.com/collect/?pid=${LINKEDIN_PARTNER_ID}&fmt=gif`;

  const element = document.createElement('img');
  element.alt = '';
  element.height = 1;
  element.width = 1;
  element.src = src;

  return element;
};

const LinkedIn = () => {
  useEffect(() => {
    initLinkedIn();
    track();
  });

  return <div className="linked-in" />;
};

export default LinkedIn;
