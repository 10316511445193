import React, { useState, useEffect, useMemo, useCallback } from 'react';

import { ProductName, RESPONSE_STATUSES, CONFIRMATION_MODAL_TYPE } from '@learned/constants';
import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import round from 'lodash/round';
import upperFirst from 'lodash/upperFirst';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import ButtonDeprecated from '~/components/Button';
import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { confirm } from '~/components/ConfirmDialog';
import { Dropdown } from '~/components/Dropdown';
import { ICONS } from '~/components/Icon';
import IconMenu from '~/components/IconMenu';
import { ConfirmationModal } from '~/components/Modals/ConfirmationModal';
import OverviewHeading from '~/components/OverviewHeading';
import ProgressBarWide from '~/components/ProgressBarWide';
import { useToasts, TOAST_TYPES } from '~/components/Toast';
import Divider from '~/components/UI/Divider';
import { TableCol } from '~/components/UI/Table';
import BaseLayout from '~/layouts/BaseLayout';
import CompanyModulesModal from '~/pages/SuperAdminDashboard/CompanyModulesModal';

import {
  CompanyEnabled,
  CompanyText,
  CompanyName,
  CompanyQuickStart,
  FilterWrapper,
  Wrapper,
  ActionsContainerWrapper,
  Progress,
  StyledRow,
  StyledTable,
  StyledSearch,
  ContextMenuContainer,
} from './SuperAdminCompanies.design';

import routes from '~/constants/routes';
import useBoolState from '~/hooks/useBoolState';
import { LS_KEYS, useLocalStorage } from '~/hooks/useLocalStorage';
import { getUser } from '~/selectors/baseGetters';
import {
  downloadCompaniesCSVSuperAdmin,
  downloadCompanyModulesCSVSuperAdmin,
  getCompaniesSuperAdmin,
  getCompanySuperAdmin,
  setCompanyEnabledSuperAdmin,
  importDemoContent,
} from '~/services/companies';
import { createCompanyDeleteRequest } from '~/services/companyDeleteRequests';
import { listIndustries } from '~/services/industries';
import { getQuickStartsSuperAdmin } from '~/services/quickStarts';
import { downloadUsersCSVSuperAdmin } from '~/services/users';
import { isProductEnabled, isProductSettingEnabled } from '~/utils/company';
import convertToTimeString from '~/utils/convertToTimeString';
import getQuickStartTotalProgress from '~/utils/getQuickStartTotalProgress';

const LS_KEY = LS_KEYS.LS_SUPER_ADMIN_COMPANIES;

const COLUMNS_KEYS = {
  NAME: 'name',
  INDUSTRY: 'industry',
  EMAIL: 'email',
  CREATED_AT: 'createdAt',
  ENABLED: 'enabled',
  MEMBERS: 'members',
  AVG_LOG_LM: 'avgLogLm',
  QUICK_START: 'quickStart',
  PRODUCT_JOB_MATRIX: 'productJobMatrix',
  PRODUCT_CAREER: 'productCareer',
  PRODUCT_PERFORMANCE: 'productPerformance',
  PRODUCT_ENGAGEMENT: 'productEngagement',
  PRODUCT_INTEGRATIONS: 'productIntegrations',
  PRODUCT_L_AND_O: 'productLearningAndOnboarding',
  SETTINGS_GOALS: 'settingsGoals',
};

const DEFAULT_SELECTED_COLUMNS = [
  COLUMNS_KEYS.INDUSTRY,
  COLUMNS_KEYS.EMAIL,
  COLUMNS_KEYS.CREATED_AT,
  COLUMNS_KEYS.ENABLED,
  COLUMNS_KEYS.MEMBERS,
  COLUMNS_KEYS.AVG_LOG_LM,
  COLUMNS_KEYS.QUICK_START,
];

const DEFAULT_FILTERS = {
  search: '',
  selectedColumns: DEFAULT_SELECTED_COLUMNS,
  selectedIndustries: [],
};

const SuperAdminCompanies = ({ i18n }) => {
  const [allCompanies, setAllCompanies] = useState({});
  const [allIndustries, setAllIndustries] = useState([]);
  const [modalCompany, setModalCompany] = useState({});
  const [companyForDemoContentConfirmationModal, setCompanyForDemoContentConfirmationModal] =
    useState(null);
  const [quickStartProgress, setQuickStartProgress] = useState({});
  const preventNavigation = useBoolState(false);
  const history = useHistory();
  const user = useSelector(getUser);
  const { addToast } = useToasts();
  const [filters, setFilters] = useLocalStorage(LS_KEY, DEFAULT_FILTERS);
  const isColSelected = (key) => key === undefined || filters.selectedColumns.includes(key);
  const isIndustriesSelected = (industryId) => filters.selectedIndustries.includes(industryId);
  const getProductStatus = (company, product) => company.products?.[product]?.status;

  const findIndustryName = useCallback(
    (industryId) => {
      const industry = allIndustries.find((item) => item.id === industryId);
      return industry?.name?.en_GB;
    },
    [allIndustries],
  );

  const cols = useMemo(() => {
    return [
      {
        title: i18n._(t`Name`),
        width: '196px',
        render: (company) => <CompanyName>{company.name}</CompanyName>,
      },
      {
        key: COLUMNS_KEYS.INDUSTRY,
        title: i18n._(t`Industry`),
        width: '196px',
        render: (company) => <CompanyText>{findIndustryName(company.industry)}</CompanyText>,
      },
      {
        key: COLUMNS_KEYS.EMAIL,
        title: i18n._(t`E-mail`),
        width: '166px',
        render: (company) => <CompanyText>{company.email}</CompanyText>,
      },
      {
        key: COLUMNS_KEYS.CREATED_AT,
        title: i18n._(t`Created at`),
        width: '136px',
        render: (company) => (
          <CompanyText>{convertToTimeString(company?.meta?.createdDate)}</CompanyText>
        ),
      },
      {
        key: COLUMNS_KEYS.ENABLED,
        title: i18n._(t`Enabled`),
        width: '96px',
        render: (company) => (
          <CompanyEnabled $enabled={company.enabled}>
            {upperFirst(company.enabled.toString())}
          </CompanyEnabled>
        ),
      },
      {
        key: COLUMNS_KEYS.MEMBERS,
        title: i18n._(t`Members`),
        width: '102px',
        render: (company) => <CompanyText>{Object.values(company.connections).length}</CompanyText>,
      },
      {
        key: COLUMNS_KEYS.AVG_LOG_LM,
        title: i18n._(t`avg. log. lm`),
        width: '102px',
        render: (company) => (
          <CompanyText>
            {company.averageLoginsLastMonth === undefined || company.averageLoginsLastMonth === null
              ? i18n._(t`N/A`)
              : round(company.averageLoginsLastMonth, 1)}
          </CompanyText>
        ),
      },
      {
        key: COLUMNS_KEYS.QUICK_START,
        title: i18n._(t`Quick Start`),
        width: '180px',
        render: (company) => (
          <>
            {company.quickStartProgress >= 0 ? (
              <CompanyQuickStart>
                <ProgressBarWide
                  height="8px"
                  value={company.quickStartProgress}
                  isActive
                  style={{ borderRadius: '6px' }}
                />
                <Progress>{company.quickStartProgress}%</Progress>
              </CompanyQuickStart>
            ) : (
              <CompanyText>ERROR</CompanyText>
            )}
          </>
        ),
      },
      {
        key: COLUMNS_KEYS.PRODUCT_JOB_MATRIX,
        title: i18n._(t`Product: Job matrix`),
        width: '180px',
        render: (company) => {
          const isJobMatrixProductEnabled = isProductEnabled(company, ProductName.JOB_MATRIX);
          const productStatus = getProductStatus(company, ProductName.JOB_MATRIX);
          return (
            <CompanyEnabled $enabled={isJobMatrixProductEnabled}>
              {upperFirst(productStatus.toString())}
            </CompanyEnabled>
          );
        },
      },
      {
        key: COLUMNS_KEYS.PRODUCT_CAREER,
        title: i18n._(t`Product: Career`),
        width: '180px',
        render: (company) => {
          const isCareerProductEnabled = isProductEnabled(company, ProductName.CAREER);
          const productStatus = getProductStatus(company, ProductName.CAREER);
          return (
            <CompanyEnabled $enabled={isCareerProductEnabled}>
              {upperFirst(productStatus.toString())}
            </CompanyEnabled>
          );
        },
      },
      {
        key: COLUMNS_KEYS.PRODUCT_PERFORMANCE,
        title: i18n._(t`Product: Performance`),
        width: '180px',
        render: (company) => {
          const isPerformanceProductEnabled = isProductEnabled(company, ProductName.PERFORMANCE);
          const productStatus = getProductStatus(company, ProductName.PERFORMANCE);
          return (
            <CompanyEnabled $enabled={isPerformanceProductEnabled}>
              {upperFirst(productStatus.toString())}
            </CompanyEnabled>
          );
        },
      },
      {
        key: COLUMNS_KEYS.PRODUCT_ENGAGEMENT,
        title: i18n._(t`Product: Engagement`),
        width: '180px',
        render: (company) => {
          const isEngagementProductEnabled = isProductEnabled(company, ProductName.ENGAGEMENT);
          const productStatus = getProductStatus(company, ProductName.ENGAGEMENT);
          return (
            <CompanyEnabled $enabled={isEngagementProductEnabled}>
              {upperFirst(productStatus.toString())}
            </CompanyEnabled>
          );
        },
      },
      {
        key: COLUMNS_KEYS.PRODUCT_INTEGRATIONS,
        title: i18n._(t`Product: Integrations`),
        width: '180px',
        render: (company) => {
          const isIntegrationsProductEnabled = isProductEnabled(company, ProductName.INTEGRATIONS);
          const productStatus = getProductStatus(company, ProductName.INTEGRATIONS);
          return (
            <CompanyEnabled $enabled={isIntegrationsProductEnabled}>
              {upperFirst(productStatus.toString())}
            </CompanyEnabled>
          );
        },
      },
      {
        key: COLUMNS_KEYS.PRODUCT_L_AND_O,
        title: i18n._(t`Product: Learning & Onboarding`),
        width: '180px',
        render: (company) => {
          const isLearningAndOnboardingProductEnabled = isProductEnabled(
            company,
            ProductName.LEARNING_AND_ONBOARDING,
          );
          const productStatus = getProductStatus(company, ProductName.LEARNING_AND_ONBOARDING);
          return (
            <CompanyEnabled $enabled={isLearningAndOnboardingProductEnabled}>
              {upperFirst(productStatus.toString())}
            </CompanyEnabled>
          );
        },
      },
      {
        key: COLUMNS_KEYS.SETTINGS_GOALS,
        title: i18n._(t`Settings: Goals`),
        width: '180px',
        render: (company) => {
          const isGoalsSettingEnabled = isProductSettingEnabled(
            company,
            ProductName.PERFORMANCE,
            'goals',
          );
          return (
            <CompanyEnabled $enabled={isGoalsSettingEnabled}>
              {upperFirst(isGoalsSettingEnabled.toString())}
            </CompanyEnabled>
          );
        },
      },
      { width: '40px', render: () => <></> },
    ];
  }, [findIndustryName, i18n]);

  const colsFiltered = cols.filter(({ key }) => isColSelected(key));

  useEffect(() => {
    const fetch = async () => {
      const [companies, industries, quickStartCompanies] = await Promise.all([
        getCompaniesSuperAdmin(),
        listIndustries({ search: '' }, { skip: 0, limit: 0, sort: { 'name.en_GB': 1 } }),
        getQuickStartsSuperAdmin(),
      ]);

      // Add quick start progress data to each company
      Object.values(quickStartCompanies).forEach((quickStart) => {
        const { company } = quickStart;

        const progress = getQuickStartTotalProgress(quickStart);

        if (companies[company]) {
          companies[company].quickStartProgress = progress;
        }
      });

      setAllCompanies(companies);
      setAllIndustries(industries.data.industries);
      setQuickStartProgress(quickStartCompanies);
    };
    fetch();
  }, []);

  const renderRow = (company) => {
    const menuItems = [
      {
        label: i18n._(company.enabled ? t`Disable` : t`Enable`),
        action: async () => {
          await setCompanyEnabledSuperAdmin(company.id, !company.enabled);
          const updatedCompany = await getCompanySuperAdmin(company.id);

          setAllCompanies({ ...allCompanies, [company.id]: updatedCompany });
        },
      },
      {
        label: i18n._(t`Delete`),
        action: async () => {
          if (
            await confirm(
              i18n,
              i18n._(
                t`Are you sure you want to PERMANENTLY delete the company ${company.name} and ALL OF ITS USERDATA with email address ${company.email} from the database? THIS ACTION CANNOT BE UNDONE!`,
              ),
            )
          ) {
            if (
              await confirm(
                i18n,
                i18n._(
                  t`Are you ENTIRELY SURE of PERMANENTLY DELETING company ${company.name} with all of its userdata?`,
                ),
              )
            ) {
              const { status } = await createCompanyDeleteRequest({
                name: company.name,
                company: company.id,
              });

              // show success toast
              if (status === RESPONSE_STATUSES.SUCCESS) {
                addToast({
                  title: i18n._(t`The DELETE company request is created!`),
                  subtitle: i18n._(t`You can find it in Delete company requests page.`),
                  type: TOAST_TYPES.INFO,
                });
              }
            } else {
              alert(i18n._(t`Company ${company.name} will be retained. Phew!`));
            }
          } else {
            alert(i18n._(t`Company ${company.name} will be retained.`));
          }
        },
      },
      {
        label: i18n._(t`Manage modules`),
        action: () => {
          setModalCompany(company);
        },
      },
      {
        label: i18n._(t`Import Demo Content`),
        action: () => {
          setCompanyForDemoContentConfirmationModal(company);
        },
      },
    ];

    return (
      <StyledRow
        onClick={() => {
          if (preventNavigation.value) {
            return false;
          }
          history.push(routes.SUPERADMIN_COMPANY_MEMBERS.url, { companyId: company.id });
        }}
        key={company.id}
      >
        {colsFiltered.map((item) => item.render(company))}
        <TableCol>
          <ContextMenuContainer
            onMouseEnter={preventNavigation.on}
            onMouseLeave={preventNavigation.off}
          >
            <IconMenu items={menuItems} />
          </ContextMenuContainer>
        </TableCol>
      </StyledRow>
    );
  };

  const handleFilters = (key, value) => setFilters((state) => ({ ...state, [key]: value }));

  const searchCompany = (company) => {
    const searchMatch =
      (!filters.search ||
        company.name?.toLowerCase().includes(filters.search.toLowerCase()) ||
        company.email?.toLowerCase().includes(filters.search.toLowerCase())) &&
      (filters.selectedIndustries.length === 0 ||
        filters.selectedIndustries.some((industry) => company.industry === industry));

    return searchMatch;
  };

  const resetFilters = () => setFilters(DEFAULT_FILTERS);

  return (
    <>
      <OverviewHeading
        title={i18n._(t`Companies`)}
        description={i18n._(
          t`Keep track of the adoption, performance and development of your workforce`,
        )}
      >
        {user?.superAdminLevel !== undefined && user?.superAdminLevel >= 1 && (
          <ButtonDeprecated
            label={i18n._(t`Export users as CSV`)}
            onClick={() => downloadUsersCSVSuperAdmin()}
          />
        )}
        <ButtonDeprecated
          label={i18n._(t`Export modules CSV`)}
          onClick={downloadCompanyModulesCSVSuperAdmin}
        />
        <ButtonDeprecated label={i18n._(t`Export CSV`)} onClick={downloadCompaniesCSVSuperAdmin} />
      </OverviewHeading>
      <BaseLayout>
        <Wrapper>
          <ActionsContainerWrapper noBottomBorder>
            <StyledSearch
              placeholder={i18n._(t`Search`)}
              value={filters.search}
              onChange={(e) => handleFilters('search', e.target.value)}
            />
            <FilterWrapper>
              <Dropdown
                items={cols.filter((item) => item.key).sort((a, b) => (b.name > a.name ? -1 : 1))} // sort a-z
                selectedItems={cols.filter((item) => item.key && isColSelected(item.key))}
                placeholder={i18n._(t`Columns`)}
                stringifyItem={(item) => item.title}
                onChange={(selectedItems) =>
                  handleFilters(
                    'selectedColumns',
                    selectedItems.map((item) => item.key),
                  )
                }
                isSingleSelect={false}
              />
            </FilterWrapper>

            <FilterWrapper>
              <Dropdown
                items={allIndustries}
                selectedItems={allIndustries.filter(
                  (item) => item.id && isIndustriesSelected(item.id),
                )}
                placeholder={i18n._(t`Industries`)}
                stringifyItem={(item) => item.name.en_GB}
                onChange={(selectedItems) =>
                  handleFilters(
                    'selectedIndustries',
                    selectedItems.map((item) => item.id),
                  )
                }
                isSingleSelect={false}
              />
            </FilterWrapper>

            <FilterWrapper>
              <Button
                variant={ButtonVariant.SECONDARY}
                size={ButtonSize.MEDIUM}
                label={i18n._(t`Reset all columns`)}
                onClick={resetFilters}
                icon={ICONS.CLOSE}
              />
            </FilterWrapper>
          </ActionsContainerWrapper>
          <Divider />
          <StyledTable
            cols={colsFiltered.map(({ render: _render, ...other }) => other)}
            items={Object.values(allCompanies).filter(searchCompany)}
            renderRow={renderRow}
            loading={isEmpty(allCompanies)}
          />
        </Wrapper>
      </BaseLayout>
      {!isEmpty(modalCompany) && (
        <CompanyModulesModal
          onClose={() => setModalCompany({})}
          modalCompany={modalCompany}
          setAllCompanies={setAllCompanies}
          allCompanies={allCompanies}
          quickStartProgress={quickStartProgress}
        />
      )}
      {companyForDemoContentConfirmationModal?.id && (
        <ConfirmationModal
          type={CONFIRMATION_MODAL_TYPE.INFO}
          description={i18n._(
            t`The content from the demo-company will be imported to the company ${companyForDemoContentConfirmationModal?.name}`,
          )}
          title={i18n._(t`Do you confirm to copy demo content?`)}
          cancelButton={i18n._(t`Cancel`)}
          submitButton={i18n._(t`Confirm`)}
          onClose={() => setCompanyForDemoContentConfirmationModal(false)}
          onSubmit={() => {
            importDemoContent(companyForDemoContentConfirmationModal.id);
            addToast({
              title: i18n._(t`Requested for Demo content`),
              subtitle: i18n._(
                t`The Demo content would appear in few minutes. Wait for Status email.`,
              ),
              type: TOAST_TYPES.INFO,
            });
          }}
        />
      )}
    </>
  );
};

export default withI18n()(SuperAdminCompanies);
