import { joiResolver } from '@hookform/resolvers/joi';
import {
  REVIEW_QUESTION_EVALUATORS,
  REVIEW_QUESTION_TYPES,
  REVIEW_STATUS,
} from '@learned/constants';
import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { endOfDay, isSameDay, startOfDay } from 'date-fns';
import Joi from 'joi';
import _ from 'lodash';
import isEmpty from 'lodash/isEmpty';

import {
  skillWithFocusAreaCheck,
  isReviewStartDateDisabled,
  getRelevantSkillCategoriesToValidate,
} from '~/pages/Reviews/utils';

import { useMultiLangString } from '~/hooks/useMultiLangString';

import type { PopulatedCareerPlan } from '../types';
import type { IJobProfile, IMultiLangString, ISkillCategory } from '@learned/types';

export const errors = {
  cycleStartDateInPast: 'cycleStartDateInPast',
  cycleStartAfterEndDate: 'cycleStartDateAfterEndDate',
  cycleEndBeforeStartDate: 'cycleEndDateBeforeStartDate',
  taskStartBeforeCycleStartDate: 'taskStartBeforeCycleStartDate',
  taskStartAfterEndDate: 'taskStartAfterEndDate',
  taskEndAfterCycleEndDate: 'taskEndAfterCycleEndDate',
  taskEndBeforeStartDate: 'taskEndBeforeStartDate',
  taskStartAndEndDateRequired: 'taskStartAndEndDateRequired',
  taskFieldHasDateType: 'taskFieldHasDateType',
  noJob: 'noJob',
  noCoach: 'noCoach',
  noSkill: 'noSkill',
  noFocusArea: 'noFocusArea',
};

const setStartOfDay = (date: Date) => startOfDay(date);
const setEndOfDay = (date: Date) => endOfDay(date);

const validateTaskStartDate =
  (evaluator: REVIEW_QUESTION_EVALUATORS) => (value: any, helpers: Joi.CustomHelpers<any>) => {
    const cycleStartDate = helpers.state.ancestors[2].settings.startDate;
    const taskEndDate = helpers.state.ancestors[0].endDate;

    // validate if required
    const evaluators = helpers.state.ancestors[2].evaluators.map(
      (evaluator: any) => evaluator.value,
    );
    const isTaskMustPresent = evaluators.includes(evaluator);
    if (isTaskMustPresent && !value) {
      return helpers.error(errors.taskStartAndEndDateRequired);
    }

    if (!value) {
      return value;
    }

    if (cycleStartDate && cycleStartDate > value && !isSameDay(value, cycleStartDate)) {
      return helpers.error(errors.taskStartBeforeCycleStartDate);
    }
    if (taskEndDate && taskEndDate < value) {
      return helpers.error(errors.taskStartAfterEndDate);
    }
    const date = _.isDate(value) ? value : new Date(value);
    return setStartOfDay(date);
  };

const validateTaskEndDate =
  (evaluator: REVIEW_QUESTION_EVALUATORS) => (value: any, helpers: Joi.CustomHelpers<any>) => {
    const cycleEndDate = helpers.state.ancestors[2].settings.endDate;
    const taskStartDate = helpers.state.ancestors[0].startDate;

    // Define if date required
    const evaluators = helpers.state.ancestors[2].evaluators.map(
      (evaluator: any) => evaluator.value,
    );
    const isTaskMustPresent = evaluators.includes(evaluator);
    if (isTaskMustPresent && !value) {
      return helpers.error(errors.taskStartAndEndDateRequired);
    }

    if (!value) {
      return value;
    }

    if (cycleEndDate && cycleEndDate < value) {
      return helpers.error(errors.taskEndAfterCycleEndDate);
    }
    if (taskStartDate && taskStartDate > value) {
      return helpers.error(errors.taskEndBeforeStartDate);
    }

    const date = _.isDate(value) ? value : new Date(value);
    return setEndOfDay(date);
  };

export const useResolver = () => {
  const getMultiLangString = useMultiLangString();

  const schema = Joi.object({
    id: Joi.string().optional(),
    name: Joi.array()
      .items(
        Joi.object({
          locale: Joi.string(),
          value: Joi.string().allow('').max(50),
        })
          .required()
          .min(1),
      )
      .custom((items: { locale: string; value: string }[]) => {
        let hasAtLeastOneName = false;
        items?.forEach((name) => {
          if (name.value !== '') {
            hasAtLeastOneName = true;
          }
        });
        if (!hasAtLeastOneName) {
          throw new Error('One translation is required');
        }

        return items;
      }),
    description: Joi.array()
      .items(
        Joi.object({
          locale: Joi.string(),
          value: Joi.string().allow(''),
        })
          .required()
          .min(1),
      )
      .custom((items: { locale: string; value: string }[]) => {
        let hasAtLeastOneDescription = false;
        items?.forEach((description) => {
          if (description.value !== '') {
            hasAtLeastOneDescription = true;
          }
        });
        if (!hasAtLeastOneDescription) {
          throw new Error('One translation is required');
        }

        return items;
      }),
    reviewTemplate: Joi.string(),
    reviewInvitationTemplate: Joi.string().allow(''),
    reviewTemplateData: Joi.any(),
    reviewTemplateName: Joi.array().items(
      Joi.object({
        locale: Joi.string(),
        value: Joi.string().allow(''),
      }),
    ),
    reviewInvitationTemplateName: Joi.array()
      .items(
        Joi.object({
          locale: Joi.string(),
          value: Joi.string().allow(''),
        }),
      )
      .allow(null),
    reviewQuestionTypes: Joi.any(),
    skillCategories: Joi.any(),
    skills: Joi.any(),
    skill: Joi.any(),
    isExpandMode: Joi.bool(),
    notifications: Joi.object(),
    privacy: Joi.object(),
    employees: Joi.array()
      .items(
        Joi.object({
          coaches: Joi.array().custom((value, helpers) => {
            if (
              helpers.state.ancestors[2].evaluators.find(
                (evaluator: any) => evaluator.value === REVIEW_QUESTION_EVALUATORS.COACH,
              ) &&
              value.length < 1
            ) {
              return helpers.error(errors.noCoach);
            }
            return value;
          }),
          careerPlans: Joi.array().custom((value, helpers) => {
            // Fetching from ancestors[2] means we are going to levels up from here. So:
            // [0] would be the employee item that we are currently in
            // [1] would be the employees Joi.array that contains the employees
            // [2] would be the entire state of the schema that containes employees
            if (
              helpers.state.ancestors[2].reviewQuestionTypes.includes(
                REVIEW_QUESTION_TYPES.SKILL_CATEGORY,
              )
            ) {
              if (value.length < 1) {
                return helpers.error(errors.noJob);
              }

              const skills = helpers.state.ancestors[2].skills;
              const skillCategories: Record<string, ISkillCategory> =
                helpers.state.ancestors[2].skillCategories;
              const jobs: IJobProfile[] = value?.map(
                (plan: PopulatedCareerPlan) => plan.jobProfile,
              );
              const categorizedSkills = {} as Record<
                string,
                {
                  skillCategory: string;
                  skillCategoryName: IMultiLangString;
                  skills: Array<string>;
                }
              >;

              Object.values(skillCategories).forEach((skillCategory) => {
                categorizedSkills[skillCategory.id] = {
                  skillCategory: skillCategory.id,
                  skillCategoryName: skillCategory.name,
                  skills: [],
                };
              });

              skills.forEach((skill: any) => {
                const { id, skillCategory } = skill;
                categorizedSkills[skillCategory].skills.push(id);
              });
              const categorizedSkillsArray = Object.values(categorizedSkills);

              // Obtain the skill categories that are relevant to be validated based on the settings of other questions in the theme
              const relevantSkillCategories = getRelevantSkillCategoriesToValidate(
                helpers.state.ancestors[2].reviewTemplateData || {},
              );

              // Go through jobs, and for each skill category that is relevant to check, show error message
              // if it does not have skills
              let isNoSkillError = false;
              const skillsWithoutFocusArea: Array<{ id: string; name: IMultiLangString }> = [];

              jobs.forEach((job) => {
                const { skillsWithFocusArea: _skillsWithFocusArea, skillsWithoutFocusArea } =
                  skillWithFocusAreaCheck({
                    job,
                    skills,
                  });

                skillsWithoutFocusArea.push(...skillsWithoutFocusArea);

                const jobSkillIds = job.skills.map((item) => item.skill);
                categorizedSkillsArray.forEach((category) => {
                  if (
                    relevantSkillCategories[category.skillCategory] &&
                    !category.skills.some((skill) => jobSkillIds.includes(skill))
                  ) {
                    isNoSkillError = true;
                  }
                });
              });

              if (isNoSkillError) {
                return helpers.error(errors.noSkill);
              }

              if (!isEmpty(skillsWithoutFocusArea)) {
                return helpers.error(errors.noFocusArea, {
                  customMessage: skillsWithoutFocusArea
                    .map(({ name }) => getMultiLangString(name))
                    .join('|'),
                });
              }
            }
            return value;
          }),
        }).options({
          allowUnknown: true,
        }),
      )
      .min(1),
    settings: Joi.object({
      startDate: Joi.date()
        .custom((value, helpers) => {
          // do not check validation if startDate field is disabled for edit
          const review = helpers.state.ancestors[1];
          const isStartDateDisabled = isReviewStartDateDisabled(review);
          if (isStartDateDisabled) {
            return value;
          }

          // validation
          const now = new Date();
          now.setHours(0, 0, 0, 0);
          if (now > value) {
            return helpers.error(errors.cycleStartDateInPast);
          }
          const cycleEndDate = helpers.state.ancestors[0].endDate;
          if (!value) {
            return value;
          }
          if (cycleEndDate && value > cycleEndDate) {
            return helpers.error(errors.cycleStartAfterEndDate);
          }
          return value;
        })
        .required(),
      endDate: Joi.date()
        .required()
        .custom((value, helpers) => {
          const cycleStartDate = helpers.state.ancestors[0].startDate;
          if (!value) {
            return value;
          }
          if (cycleStartDate && value < cycleStartDate) {
            return helpers.error(errors.cycleEndBeforeStartDate);
          }
          return value;
        })
        .required(),
      isCoachesAskedToScheduleReview: Joi.boolean(),
      isAutoArchive: Joi.boolean(),
      isCalibrate: Joi.boolean(),
      isDigitalSign: Joi.boolean(),
      isAutoTimeline: Joi.boolean(),
    }),
    tasks: Joi.object({
      reviewSelfEvaluate: Joi.object({
        startDate: Joi.custom(validateTaskStartDate(REVIEW_QUESTION_EVALUATORS.EMPLOYEE)),
        endDate: Joi.custom(validateTaskEndDate(REVIEW_QUESTION_EVALUATORS.EMPLOYEE)),
      }),
      reviewPeerEvaluate: Joi.object({
        startDate: Joi.custom(validateTaskStartDate(REVIEW_QUESTION_EVALUATORS.PEER)),
        endDate: Joi.custom(validateTaskEndDate(REVIEW_QUESTION_EVALUATORS.PEER)),
      }),
      reviewCoachEvaluate: Joi.object({
        startDate: Joi.custom(validateTaskStartDate(REVIEW_QUESTION_EVALUATORS.COACH)),
        endDate: Joi.custom(validateTaskEndDate(REVIEW_QUESTION_EVALUATORS.COACH)),
      }),
      reviewPeerNominate: Joi.object({
        startDate: Joi.custom(validateTaskStartDate(REVIEW_QUESTION_EVALUATORS.PEER)),
        endDate: Joi.custom(validateTaskEndDate(REVIEW_QUESTION_EVALUATORS.PEER)),
        description: Joi.array().items(
          Joi.object({
            locale: Joi.string(),
            value: Joi.string().allow(''),
          }),
        ),
      }),
      isPreviouslyAutoGenerateEnabled: Joi.boolean(),
    }),
    status: Joi.string().valid(...Object.values(REVIEW_STATUS)),
    evaluators: Joi.array(),
    fetchedReview: Joi.any(),
    userReview: Joi.any(),
  }).options({
    messages: {
      [errors.cycleStartDateInPast]: i18n._(t`Start date cannot be in the past`),
      [errors.cycleStartAfterEndDate]: i18n._(t`Start date cannot be after the end date`),
      [errors.cycleEndBeforeStartDate]: i18n._(t`End date cannot be before the start date`),
      [errors.taskStartBeforeCycleStartDate]: i18n._(
        t`Start date cannot be before start date review`,
      ),
      [errors.taskStartAfterEndDate]: i18n._(t`Start date cannot be after the deadline`),
      [errors.taskEndAfterCycleEndDate]: i18n._(t`Deadline cannot be after end date review`),
      [errors.taskEndBeforeStartDate]: i18n._(t`Deadline cannot be before the start date`),
      [errors.taskStartAndEndDateRequired]: i18n._(t`Start date and end date are required`),
      [errors.taskFieldHasDateType]: i18n._(t`Start date or end date have unknown format`),
      [errors.noJob]: i18n._(t`Select at least one job`),
      [errors.noCoach]: i18n._(t`Select at least one coach`),
      [errors.noSkill]: i18n._(t`No Skill`),
      [errors.noFocusArea]: '{#customMessage}',
      'date.base': i18n._(t`Cannot be empty`),
    },
  });

  const resolver = joiResolver(schema);

  return { resolver };
};
