import React from 'react';

import useScript from '~/hooks/useScript';

const innerHTML = `
  (function(e,t,o,n,p,r,i){e.visitorGlobalObjectAlias=n;e[e.visitorGlobalObjectAlias]=e[e.visitorGlobalObjectAlias]||function(){(e[e.visitorGlobalObjectAlias].q=e[e.visitorGlobalObjectAlias].q||[]).push(arguments)};e[e.visitorGlobalObjectAlias].l=(new Date).getTime();r=t.createElement("script");r.src=o;r.async=true;i=t.getElementsByTagName("script")[0];i.parentNode.insertBefore(r,i)})(window,document,"https://diffuser-cdn.app-us1.com/diffuser/diffuser.js","vgo");
    vgo('setAccount', '252490579');
    vgo('setTrackByDefault', true);

    vgo('process');
`;

// CRM connection
const ActiveCampaignPixel = () => {
  useScript({
    type: 'text/javascript',
    innerHTML,
  });

  return <div className="active-campaign-pixel" />;
};

export default ActiveCampaignPixel;
