import React, { useEffect, useMemo } from 'react';

import {
  CONFIRMATION_MODAL_TYPE,
  ProductName,
  REVIEW_QUESTION_TYPES,
  REVIEW_STATUS,
  ROLES,
} from '@learned/constants';
import {
  ICompany,
  IReviewQuestionCustomSkill,
  IReviewQuestionRating,
  IReviewQuestionSkillCategory,
} from '@learned/types';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import qs from 'qs';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';

import { ButtonVariant } from '~/components/Buttons';
import { HeaderFocusMode } from '~/components/Headers/HeaderFocusMode';
import { ICONS } from '~/components/Icon';
import { confirm } from '~/components/Modals/ConfirmationModal/confirm';
import { ProductSettingsModal, AUTO_SCROLL } from '~/components/Modals/ProductSettingsModal';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';
import { SideBar } from '~/components/SideBar';
import { useSectionState } from '~/components/SideBar/SectionStateHook';
import { TOAST_TYPES, useToasts } from '~/components/Toast';
import { LoadingModal } from '~/pages/Reviews/components/LoadingModal';

import { Wrapper } from './design';
import { useReview } from './hooks/useReview';
import { useReviewInvitation } from './hooks/useReviewInvitation';
import { useReviewTasks } from './hooks/useReviewTasks';
import { StepEmployees } from './StepEmployees';
import { StepFinalCheck } from './StepFinalCheck';
import { StepGeneral } from './StepGeneral';
import { StepSettings } from './StepSettings';
import { StepTimeline } from './StepTimeline';

import routes from '~/constants/routes';
import useBoolState from '~/hooks/useBoolState';
import { useFromQuery } from '~/hooks/useFromQuery';
import type { ILanguageStateReturn } from '~/hooks/useLanguageState';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import { getUser } from '~/selectors/baseGetters';
import getCurrentCompany from '~/selectors/getCurrentCompany';
import { turnArrayIntoMultiLang } from '~/utils/turnMultiLangIntoArray';

import type { IReviewCycleForm } from './types';
import type { UseFormReturn } from 'react-hook-form';

interface ReviewCycleFormProps {
  formMethods: UseFormReturn<IReviewCycleForm>;
  languageState: ILanguageStateReturn;
}

const ReviewCycleForm = ({ formMethods, languageState }: ReviewCycleFormProps) => {
  const { i18n } = useLingui();
  const history = useHistory();
  const sectionState = useSectionState([]);
  const params: Record<string, string | undefined> = useParams();
  const reviewId = params.reviewId as string;
  const getMultiLangString = useMultiLangString();
  const { addToast } = useToasts();
  const currentCompany = useSelector(getCurrentCompany);
  const currentUser = useSelector(getUser);

  const {
    products: {
      performance: {
        settings: {
          labels: { ratingLabels, skillLabels },
        },
      },
    },
  } = currentCompany as ICompany;

  const query = qs.parse(location.search, { ignoreQueryPrefix: true });
  const isCreatingNew = query.isCreatingNew;
  const currentSection = query.currentSection;
  const { goBack } = useFromQuery({ includeHash: true });
  const performanceSettingsModal = useBoolState(false);

  const { watch, handleSubmit, formState, trigger } = formMethods;

  const {
    saveReview,
    isReviewLoading,
    deleteReview,
    isReviewSaving,
    reviewTemplates,
    defineSelectedTemplate,
  } = useReview({
    formMethods,
    reviewId,
  });
  const { autoGenerateDates } = useReviewTasks({ formMethods });
  const { autoSelectInvitationTemplate, invitationTemplates, refreshInvitationTemplates } =
    useReviewInvitation({ formMethods, languageState });
  const { errors } = formState;

  const watchEmployees = watch('employees');
  const cycleStartDate = watch('settings.startDate');
  const isAutoGenerate = watch('settings.isAutoTimeline');
  const watchReviewTemplate = watch('reviewTemplate');
  const watchFetchedCycle = watch('fetchedCycle');

  useEffect(() => {
    const defaultSections = [
      {
        title: i18n._(t`General`),
      },
      {
        title: i18n._(t`Select employees`),
      },
      {
        title: i18n._(t`Settings`),
        fields: [i18n._(t`General`), i18n._(t`Notifications`), i18n._(t`Privacy`)],
      },
      {
        title: i18n._(t`Timeline`),
        fields: [i18n._(t`Invitation`), i18n._(t`Timeline`)],
      },
      {
        title: i18n._(t`Final check`),
      },
    ];
    sectionState.setSections(defaultSections);

    // redirect to section, based on query
    if (currentSection && defaultSections[Number(currentSection)]) {
      sectionState.setCurrentSection(Number(currentSection));
    }

    if (isCreatingNew) {
      autoGenerateDates();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isReviewLoading) {
      autoSelectInvitationTemplate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReviewLoading]);

  useEffect(() => {
    sectionState.triedToSubmit ? trigger('employees') : null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchEmployees]);

  useEffect(() => {
    if (isAutoGenerate) {
      autoGenerateDates();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAutoGenerate, cycleStartDate]);

  const nameMultiLang = turnArrayIntoMultiLang(watch('name'));
  const watchStatus = watch('status');
  const isDraft = watchStatus === REVIEW_STATUS.DRAFT;

  const onSaveReview = async () => {
    trigger('name');
    if (errors.name) {
      return;
    }

    const response = await saveReview();
    if (response.code === 200) {
      addToast({ title: i18n._(t`Review cycle saved as draft`), type: TOAST_TYPES.SUCCESS });
    }
    goBack();
  };

  const onPublishReview = async () => {
    if (!isEmpty(errors)) {
      return;
    }

    const response = await saveReview(isDraft ? REVIEW_STATUS.PUBLISHED : undefined);
    if (response.code === 200) {
      addToast({
        title: `Review cycle ${isDraft ? 'published' : 'saved'}`,
        type: TOAST_TYPES.SUCCESS,
      });
    }
    goBack();
  };

  const goToReviewCycles = () =>
    history.push(
      routes.REVIEWS.build(
        // @ts-ignore
        { role: ROLES.USER },
        { hash: 'review-cycles' },
      ),
    );

  const onCloseButton = async () => {
    if (isEmpty(formState.dirtyFields)) {
      goToReviewCycles();
    } else {
      const isConfirmed = await confirm({
        type: CONFIRMATION_MODAL_TYPE.INFO,
        title: i18n._(t`Close without saving?`),
        description: i18n._(
          t`Are you sure you want to close without saving? This action cannot be undone.`,
        ),
        submitButton: !isDraft && i18n._(t`Save changes`),
      });
      if (isConfirmed) {
        isDraft ? onSaveReview() : onSaveNotDraftCycle();
      } else {
        goToReviewCycles();
      }
    }
  };

  useEffect(() => {
    sectionState.setErrorSection(0, !!errors.name || !!errors.reviewTemplate);
    sectionState.setErrorSection(1, !!errors.employees);
    sectionState.setErrorSection(
      3,
      !!errors.settings ||
        !!errors.tasks ||
        !!errors.reviewInvitationTemplate ||
        !!errors.description,
    );
    if (errors) {
      sectionState.goToFirstErrorSection();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  const ratingScaleValidation = useMemo(() => {
    if (watchReviewTemplate) {
      const questions = isDraft
        ? reviewTemplates.find(({ id }) => id === watchReviewTemplate)?.questions || []
        : // @ts-ignore
          Object.values(watchFetchedCycle.backup.reviewQuestions || {});
      const ratingQuestions = (questions as unknown as IReviewQuestionRating[]).filter(
        ({ settings, type }) => !settings.isManualScale && type === REVIEW_QUESTION_TYPES.RATING,
      );
      const skillQuestions = (
        questions as unknown as Array<IReviewQuestionCustomSkill | IReviewQuestionSkillCategory>
      ).filter(
        ({ settings, type }) =>
          !settings.isManualScale &&
          [REVIEW_QUESTION_TYPES.CUSTOM_SKILL, REVIEW_QUESTION_TYPES.SKILL_CATEGORY].includes(type),
      );

      // if there's no rating labels or there is empty field
      if (
        !isEmpty(ratingQuestions) &&
        (isEmpty(ratingLabels) ||
          !ratingLabels.every((item) => Object.values(item.name).find((value) => value)))
      ) {
        return false;
      }

      // if there's no skill labels or there is empty field
      if (
        !isEmpty(skillQuestions) &&
        (isEmpty(skillLabels) ||
          !skillLabels.every((item) => Object.values(item.name).find((value) => value)))
      ) {
        return false;
      }

      return true;
    }

    return true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify({ watchReviewTemplate, ratingLabels, skillLabels })]);

  const onClickPublish = async () => {
    if (ratingScaleValidation) {
      const isConfirmed = await confirm({
        type: CONFIRMATION_MODAL_TYPE.WARNING,
        title: i18n._(t`Publish review cycle?`),
        description: i18n._(
          t`Are you sure you want to publish the review cycle? From the start date, the review cycle will be available to all participants.`,
        ),
      });
      if (isConfirmed) {
        sectionState.setTriedToSubmit();
        await handleSubmit(onPublishReview)();
      }
    } else {
      const isConfirmed = await confirm({
        type: CONFIRMATION_MODAL_TYPE.WARNING,
        title: currentUser.isAdmin
          ? i18n._(t`Rating scale incomplete`)
          : i18n._(t`Not possible to create review`),
        description: currentUser.isAdmin
          ? i18n._(
              t`It is not possible to publish this review because the rating scale is not complete. 
                Press 'continue' to go to the performance settings, where you can fill in the blanc fields of the rating scale.`,
            )
          : i18n._(
              t`It is not possible to publish this review because the rating scale is not complete.
                Contact HR to manage this in the performance settings.
                Press 'Continue' to save the review as draft.`,
            ),
      });

      if (isConfirmed) {
        if (currentUser.isAdmin) {
          performanceSettingsModal.on();
        } else {
          onSaveReview();
        }
      }
    }
  };

  const onSaveNotDraftCycle = () => {
    sectionState.setTriedToSubmit();
    handleSubmit(onPublishReview)();
  };

  const onDelete = async () => {
    const confirmResult = await confirm({
      type: CONFIRMATION_MODAL_TYPE.DELETE,
      title: i18n._(t`Delete review cycle?`),
      description:
        formMethods.getValues('status') === REVIEW_STATUS.DRAFT
          ? i18n._(
              t`Are you sure you want to delete this review cycle? This action cannot be undone.`,
            )
          : i18n._(
              t`Are you sure you want to delete this review cycle? All reviews within this cycle will be deleted. This action cannot be undone.`,
            ),
    });

    if (confirmResult) {
      await deleteReview();
      addToast({
        title: i18n._(t`Review cycle deleted`),
        type: TOAST_TYPES.SUCCESS,
      });
      goToReviewCycles();
    }
  };

  return (
    <>
      <HeaderFocusMode
        title={i18n._(
          t`${isCreatingNew ? 'Create' : 'Edit'} review cycle: ${getMultiLangString(
            nameMultiLang,
          )}`,
        )}
        goBack={onCloseButton}
        languageState={languageState}
        submitButton={
          isDraft
            ? {
                title: i18n._(t`Publish`),
                type: ButtonVariant.PRIMARY,
                onClick: onClickPublish,
              }
            : undefined
        }
        iconButtons={[
          {
            icon: ICONS.DELETE_BIN,
            tooltip: i18n._(t`Delete`),
            onClick: async () => {
              await onDelete();
            },
          },
          {
            icon: ICONS.SAVE,
            tooltip: i18n._(t`${isDraft ? 'Save draft' : 'Save'}`),
            onClick: isDraft ? onSaveReview : onSaveNotDraftCycle,
          },
        ]}
      />
      <Wrapper isExpandMode={watch('isExpandMode')}>
        <ShowSpinnerIfLoading loading={isReviewLoading}>
          {!watch('isExpandMode') && (
            <SideBar
              sections={sectionState.sections}
              currentSection={sectionState.currentSection}
              setCurrentSection={sectionState.setCurrentSection}
            />
          )}
          <ShowSpinnerIfLoading loading={false}>
            {sectionState.currentSection === 0 && (
              <StepGeneral
                formMethods={formMethods}
                sectionState={sectionState}
                languageState={languageState}
                reviewTemplates={reviewTemplates}
                defineSelectedTemplate={defineSelectedTemplate}
              />
            )}
            {sectionState.currentSection === 1 && (
              <StepEmployees formMethods={formMethods} sectionState={sectionState} />
            )}
            {sectionState.currentSection === 2 && (
              <StepSettings formMethods={formMethods} sectionState={sectionState} />
            )}
            {sectionState.currentSection === 3 && (
              <StepTimeline
                formMethods={formMethods}
                sectionState={sectionState}
                languageState={languageState}
                invitationTemplates={invitationTemplates}
                refreshInvitationTemplates={refreshInvitationTemplates}
              />
            )}
            {sectionState.currentSection === 4 && (
              <StepFinalCheck
                formMethods={formMethods}
                sectionState={sectionState}
                onPublish={isDraft ? onClickPublish : onSaveNotDraftCycle}
                publishLabel={isDraft ? i18n._(t`Publish`) : i18n._(t`Save`)}
              />
            )}
          </ShowSpinnerIfLoading>
        </ShowSpinnerIfLoading>
      </Wrapper>
      {isReviewSaving && <LoadingModal title={i18n._(t`Saving, please wait...`)} />}
      {performanceSettingsModal.value && (
        <ProductSettingsModal
          name={ProductName.PERFORMANCE}
          closeModal={performanceSettingsModal.off}
          autoScroll={AUTO_SCROLL.LABELS}
        />
      )}
    </>
  );
};

export { ReviewCycleForm };
