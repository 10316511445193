import { useEffect, useState } from 'react';

import {
  API_RETURN_FIELDS,
  CAREER_PLAN_STATUSES,
  CONNECTION_STATUSES,
  REVIEW_QUESTION_EVALUATORS,
  REVIEW_QUESTION_TYPES,
  REVIEW_QUESTION_TYPES_V1,
  REVIEW_STATUS,
} from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import _, { orderBy, uniqBy } from 'lodash';
import flatten from 'lodash/flatten';
import uniq from 'lodash/uniq';
import { useSelector } from 'react-redux';

import { ICONS } from '~/components/Icon';
import { useInactiveUsers } from '~/pages/Reviews/hooks/useInactiveUsers';
import { useReviewTemplates } from '~/pages/Reviews/hooks/useReviewTemplates';
import { transformToISOString } from '~/pages/Reviews/utils';

import { useReviewTasks } from './useReviewTasks';

import useBoolState from '~/hooks/useBoolState';
import { useLanguageState } from '~/hooks/useLanguageState';
import { getSettingsRole, getUser } from '~/selectors/baseGetters';
import { getCareerPlansAsAdminCoach } from '~/services/careerPlans';
import { deleteReviewById, fetchReviewById, updateReviewById } from '~/services/reviews';
import { getUserReviews } from '~/services/userReviews';
import { getCompanyUsers } from '~/services/users';
import { turnArrayIntoMultiLang, turnMultiLangIntoArray } from '~/utils/turnMultiLangIntoArray';
import { isNotNil } from '~/utils/typePredicates';

import type { IEmployee, IReviewCycleForm } from '../types';
import type {
  IAddUserReviews,
  ICareerPlan,
  IDeleteUserReviews,
  IEditUserReviews,
  IReview,
  IUser,
  IUserReview,
  IMultiLangString,
  IReviewQuestion,
  IReviewTemplate,
  WithEvaluators,
} from '@learned/types';
import type { UseFormReturn } from 'react-hook-form';

interface UseReviewProps {
  formMethods: UseFormReturn<IReviewCycleForm>;
  reviewId: IReview['id'];
}

export const useReview = ({ formMethods, reviewId }: UseReviewProps) => {
  const { i18n } = useLingui();
  const {
    setValue,
    watch,
    formState: { dirtyFields },
  } = formMethods;
  const languageState = useLanguageState(true);
  const $isReviewLoading = useBoolState(true);
  const $isReviewSaving = useBoolState(false);
  const { removeInactiveUsers } = useInactiveUsers();
  const { reviewTemplates } = useReviewTemplates();
  const { setTasksDates } = useReviewTasks({ formMethods });

  const [item, setItem] = useState<IReview>();
  const user = useSelector(getUser);
  const userRole = useSelector(getSettingsRole);

  const watchEmployees = watch('employees');

  const fetchCareerPlans = async () => {
    const res = await getCareerPlansAsAdminCoach(
      {
        status: CAREER_PLAN_STATUSES.CURRENT,
      },
      {},
      userRole,
    );

    return Object.values(res as Record<string, ICareerPlan>);
  };

  const fetchCompanyUsers = async (users: string[]): Promise<IUser[]> => {
    const { data } = await getCompanyUsers(
      {
        statuses: [CONNECTION_STATUSES.ACTIVE],
        users,
      },
      ['teams', 'jobProfiles', 'coaches'],
    );

    return Object.values(data?.users ?? {});
  };

  const addEmployees = async (employees: IEmployee[]) => {
    const careerPlans = await fetchCareerPlans();

    setValue(
      'employees',
      uniqBy(
        [
          ...watchEmployees,
          ...employees.map((employee) => {
            const availableCareerPlans = careerPlans.filter(
              (item) => item.createdFor === employee.id,
            );
            const plansForUser = availableCareerPlans.filter((item) =>
              employee.careerPlanIds?.includes(item.id),
            );
            const availableJobProfiles = employee.jobProfiles || [];
            const jobProfileIds = plansForUser?.map((plan) => plan.jobProfile);
            const jobProfiles = availableJobProfiles.filter((item) =>
              jobProfileIds.includes(item.id),
            );

            return {
              ...employee,
              coaches: removeInactiveUsers(employee.coaches),
              guests: removeInactiveUsers(employee.guests) || [],
              availableCoaches: removeInactiveUsers(employee.availableCoaches) || [],
              availableJobProfiles,
              jobProfiles: jobProfiles || [],
              availableCareerPlans,
              careerPlans: plansForUser || [],
            };
          }),
        ],
        'id',
      ),
    );
  };

  const getEmployeesFromUserReviews = async (userReviews: IUserReview[]) => {
    const users = await fetchCompanyUsers(userReviews.map((review) => review.createdFor));
    const employees: Array<IEmployee> = [];

    userReviews.forEach((review) => {
      const createdForUser = users.find((user: IUser) => user.id === review.createdFor);

      // @ts-ignore
      employees.push({
        ...createdForUser,
        id: review.createdFor, // !important we need this in case user does not exist in the company anymore, but he still present in review cycle
        coaches: review.coaches,
        // @ts-ignore
        availableCoaches: createdForUser?.coaches || [],
        guests: review.guests,
        userReview: review.id,
        careerPlanIds: review.careerPlans,
      });
    });

    return employees;
  };

  const fetchReview = async () => {
    const result = await fetchReviewById(reviewId);
    const review: IReview = result.data[API_RETURN_FIELDS.REVIEW];
    setItem(review);
    return review;
  };

  const fetchUserReviews = async () => {
    const result = await getUserReviews({ filters: { review: [reviewId] }, options: {} });
    const userReviews: IUserReview[] = Object.values(
      result.data[API_RETURN_FIELDS.USER_REVIEWS] || {},
    );

    setValue(
      'userReviews',
      userReviews.map((review) => review.id),
    );
    const employees = await getEmployeesFromUserReviews(userReviews);
    await addEmployees(employees);
  };

  // if backup exist (review not in DRAFT) - we get template from backup
  // because it's not possible to change anymore
  const defineSelectedTemplate = (reviewFromProps?: IReview) => {
    const selectedReviewTemplateId = watch('reviewTemplate');

    const review = reviewFromProps || watch('fetchedCycle');
    const isDraft = review.status === REVIEW_STATUS.DRAFT;

    // @ts-ignore
    const reviewTemplateFromBackup = review?.backup?.reviewTemplate;
    // @ts-ignore
    const reviewQuestionsFromBackup = review?.backup?.reviewQuestions;
    // if backup exist (review not in DRAFT) - we get template from backup
    // because it's not possible to change anymore
    const selectedTemplate: IReviewTemplate | undefined = isDraft
      ? reviewTemplates.find((item) => item.id === selectedReviewTemplateId)
      : // get template from backup
        ({
          ...reviewTemplateFromBackup,
          // populate questions from backup
          questions: reviewTemplateFromBackup?.questions.map(
            // @ts-ignore
            (questionId) => reviewQuestionsFromBackup[questionId],
          ),
        } as unknown as IReviewTemplate);

    return selectedTemplate;
  };

  const setFormValues = async () => {
    const review = await fetchReview();
    await fetchUserReviews();

    setValue('notifications', review.notifications);
    setValue('name', turnMultiLangIntoArray(review.name, languageState.companyLanguages));
    setValue('reviewTemplate', review.reviewTemplate);
    setValue('privacy', review.privacy);
    setValue('settings', {
      // @ts-ignore
      isAutoTimeline: true,
      ...review.settings,
      startDate: new Date(review.settings.startDate),
      endDate: review.settings.endDate ? new Date(review.settings.endDate) : null,
    });
    setValue('status', review.status);
    setValue('reviewInvitationTemplate', review.reviewInvitationTemplate);
    setValue(
      'description',
      turnMultiLangIntoArray(review.description, languageState.companyLanguages),
    );
    setValue(
      'tasks.reviewPeerNominate.description',
      turnMultiLangIntoArray(
        review.tasks?.reviewPeerNominate?.description || {},
        languageState.companyLanguages,
      ) || [],
    );
    setTasksDates(review);
    setValue('fetchedCycle', review);

    const selectedTemplate = defineSelectedTemplate(review);

    const questions = selectedTemplate?.questions as unknown as IReviewQuestion[];
    const uniqQuestionTypes = uniq(questions?.map((item) => item?.type));
    const uniqEvaluators: REVIEW_QUESTION_EVALUATORS[] = uniq(
      flatten(questions?.map((item) => (item?.settings as WithEvaluators)?.evaluators)),
    );

    // define evaluators
    const evaluators = orderBy(
      uniqEvaluators
        .map((item) => {
          switch (item) {
            case REVIEW_QUESTION_EVALUATORS.EMPLOYEE:
              return {
                value: REVIEW_QUESTION_EVALUATORS.EMPLOYEE,
                icon: ICONS.EMPLOYEE,
                title: i18n._(t`Employees`),
                rank: 1,
              };
            case REVIEW_QUESTION_EVALUATORS.COACH:
              return {
                value: REVIEW_QUESTION_EVALUATORS.COACH,
                icon: ICONS.COACH,
                title: i18n._(t`Coaches`),
                rank: 2,
              };
            case REVIEW_QUESTION_EVALUATORS.PEER:
              return {
                value: REVIEW_QUESTION_EVALUATORS.PEER,
                icon: ICONS.PEER,
                title: i18n._(t`Peers`),
                rank: 3,
              };
            default:
              return null;
          }
        })
        .filter(isNotNil),
      ['rank'],
    );
    setValue('evaluators', evaluators);

    setValue('reviewQuestionTypes', uniqQuestionTypes);

    setValue('reviewTemplateData', selectedTemplate);
    setValue(
      'reviewTemplateName',
      turnMultiLangIntoArray(
        (selectedTemplate?.name as IMultiLangString) || '',
        languageState.companyLanguages,
      ),
    );

    $isReviewLoading.off();
  };

  const saveReview = async (status?: REVIEW_STATUS) => {
    $isReviewSaving.on();
    const evaluators = watch('evaluators').map(
      (evaluator: { value: REVIEW_QUESTION_EVALUATORS; icon: ICONS; title: string }) =>
        evaluator.value,
    );

    const isCoachesVisible = evaluators.includes(REVIEW_QUESTION_EVALUATORS.COACH);
    const isJobsVisible = watch('reviewQuestionTypes').some((type) =>
      [REVIEW_QUESTION_TYPES.SKILL_CATEGORY, REVIEW_QUESTION_TYPES_V1.JOB_PROFILE_V1].includes(
        type,
      ),
    );

    const review: any = {
      name: turnArrayIntoMultiLang(watch('name')),
      notifications: watch('notifications'),
      reviewTemplate: watch('reviewTemplate') || null,
      privacy: watch('privacy'),
      settings: {
        ...watch('settings'),
        startDate: transformToISOString(watch('settings.startDate')),
        endDate: transformToISOString(watch('settings.endDate')),
      },
      status: status ? status : watch('status'),
      description: turnArrayIntoMultiLang(watch('description') || []),
      tasks: {},
      reviewInvitationTemplate: watch('reviewInvitationTemplate') || null,
    };

    if (evaluators.includes(REVIEW_QUESTION_EVALUATORS.PEER)) {
      review.tasks.reviewPeerEvaluate = {
        startDate: transformToISOString(watch('tasks.reviewPeerEvaluate.startDate')),
        endDate: transformToISOString(watch('tasks.reviewPeerEvaluate.endDate')),
      };
      review.tasks.reviewPeerNominate = {
        startDate: transformToISOString(watch('tasks.reviewPeerNominate.startDate')),
        endDate: transformToISOString(watch('tasks.reviewPeerNominate.endDate')),
        description: turnArrayIntoMultiLang(watch('tasks.reviewPeerNominate.description') || []),
      };
    }

    if (evaluators.includes(REVIEW_QUESTION_EVALUATORS.COACH)) {
      review.tasks.reviewCoachEvaluate = {
        startDate: transformToISOString(watch('tasks.reviewCoachEvaluate.startDate')),
        endDate: transformToISOString(watch('tasks.reviewCoachEvaluate.endDate')),
      };
    }

    if (evaluators.includes(REVIEW_QUESTION_EVALUATORS.EMPLOYEE)) {
      review.tasks.reviewSelfEvaluate = {
        startDate: transformToISOString(watch('tasks.reviewSelfEvaluate.startDate')),
        endDate: transformToISOString(watch('tasks.reviewSelfEvaluate.endDate')),
      };
    }

    const addUserReviews: IAddUserReviews[] = [];
    const editUserReviews: IEditUserReviews[] = [];
    const availableUserReviews: string[] = [];

    watch('employees').forEach((employee) => {
      const jobProfileIds = employee.jobProfiles.map((job) => job.id);
      const filteredPlans = employee.availableCareerPlans?.filter((plan) =>
        jobProfileIds.includes(plan.jobProfile),
      );
      const coaches = isCoachesVisible ? employee.coaches : [];
      const careerPlans = filteredPlans?.map((plan) => plan.id) || [];

      if (employee.userReview) {
        availableUserReviews.push(employee.userReview);
        editUserReviews.push({
          id: employee.userReview as string,
          coaches,
          guests: employee.guests,
          careerPlans: isJobsVisible ? careerPlans : [],
        });
      } else {
        addUserReviews.push({
          createdFor: employee.id,
          coaches,
          guests: employee.guests,
          careerPlans: isJobsVisible ? careerPlans : [],
        });
      }
    });

    const deleteUserReviews: IDeleteUserReviews[] =
      watch('userReviews')?.filter((review) => !availableUserReviews.includes(review)) || [];

    let result;

    try {
      // @ts-ignore
      result = await updateReviewById(reviewId, {
        review,
        // @ts-ignore
        addUserReviews,
        editUserReviews: dirtyFields.employees ? editUserReviews : [],
        deleteUserReviews,
      });
    } finally {
      // turn off loading modal if error or success
      $isReviewSaving.off();
    }

    return result;
  };

  const deleteReview = async () => {
    if (item && (user.isAdmin || (user.isCoach && item?.createdBy === user.id))) {
      await deleteReviewById(item.id);
    }
  };

  useEffect(() => {
    setFormValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reviewId]);

  return {
    saveReview,
    isReviewLoading: $isReviewLoading.value,
    isReviewSaving: $isReviewSaving.value,
    deleteReview,
    reviewTemplates,
    defineSelectedTemplate,
  };
};
