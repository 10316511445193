import React, { useContext, useState } from 'react';

import { REPORT_TYPES } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { TOAST_TYPES, useToasts } from '~/components/Toast';

import { EngagementReportContext } from './EngagementContext';
import { EngagementHeader } from './EngagementHeader';
import { EngagementModal } from './EngagementModal';
import { TabBody } from './LayoutStyles';

import * as reportsService from '~/services/reports';
import { TGetEngagementDetailsPayload } from '~/services/reports';
import { finishGetCustomReports, startGetCustomReports } from '~/store/customReports/actions';

import { TForm, TOption } from '../types';

type TProps = {
  isLoading: boolean;
  children: React.ReactNode;
  setDownloadReportType?: React.Dispatch<React.SetStateAction<string | undefined>>;
};

const HeaderIncluded = ({ isLoading, children, setDownloadReportType }: TProps): JSX.Element => {
  const { i18n } = useLingui();
  const [onCreateLoader, setOnCreateLoader] = useState(false);
  const [showSaveDropdown, setShowSaveDropdown] = useState(false);
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [showDownloadDropdown, setShowDownloadDropdown] = useState(false);

  const { addToast } = useToasts();

  const {
    tabConfiguration,
    reportType,
    reportId,
    showMenu,
    dimensions,
    filters,
    options,
    sorting,
  } = useContext(EngagementReportContext);
  const reportTitle =
    reportType === REPORT_TYPES.ENGAGEMENT ? i18n._(t`Engagement`) : i18n._(t`Performance`);

  const dispatch = useDispatch();
  const {
    watch,
    register,
    setValue,
    formState: { errors },
  } = useForm<TForm>({
    mode: 'all',
    defaultValues: {
      newName: `${reportTitle}_copy`,
    },
  });

  const getItemKeys = (items: TOption[]) => items.map((item) => item.key);

  const onUpdateOrCreateReport = async (isUpdate: boolean) => {
    try {
      setOnCreateLoader(true);
      const reportName = watch('newName');
      const payload = {
        ...tabConfiguration,
        name: isUpdate ? tabConfiguration?.name : reportName,
        sourceTab: tabConfiguration?.tabId,
        primaryDimension: {
          ...tabConfiguration?.primaryDimension,
          ...(dimensions.primary && { value: dimensions.primary }),
        },
        secondaryDimension: {
          ...tabConfiguration?.secondaryDimension,
          ...(dimensions.secondary && { value: dimensions.secondary }),
        },
        measure: {
          ...tabConfiguration?.measure,
          ...(dimensions.measure && { value: dimensions.measure }),
        },
        filters: {
          ...tabConfiguration?.filters,
          timeFrame: {
            ...tabConfiguration?.filters?.timeFrame,
            ...(filters.monthSelected && { value: filters.monthSelected }),
          },
          themes: {
            ...tabConfiguration?.filters?.themes,
            ...(filters.themesOptionSelected && {
              value: getItemKeys(filters.themesOptionSelected),
            }),
          },
          teams: {
            ...tabConfiguration?.filters?.teams,
            ...(filters.teamsOptionSelected && {
              value: getItemKeys(filters.teamsOptionSelected),
            }),
          },
          surveys: {
            ...tabConfiguration?.filters?.surveys,
            ...(filters.surveysOptionSelected && {
              value: getItemKeys(filters.surveysOptionSelected),
            }),
          },
          jobs: {
            ...tabConfiguration?.filters?.jobs,
            ...(filters.jobsSelected && { value: getItemKeys(filters.jobsSelected) }),
          },
          jobGroups: {
            ...tabConfiguration?.filters?.jobGroups,
            ...(filters.jobsGroupsSelected && {
              value: getItemKeys(filters.jobsGroupsSelected),
            }),
          },
          educationLevels: {
            ...tabConfiguration?.filters?.educationLevels,
            ...(filters.educationLevelsSelected && {
              value: getItemKeys(filters.educationLevelsSelected),
            }),
          },
          genders: {
            ...tabConfiguration?.filters?.genders,
            ...(filters.gendersSelected && { value: getItemKeys(filters.gendersSelected) }),
          },
          ageGroups: {
            ...tabConfiguration?.filters?.ageGroups,
            ...(filters.ageGroupSelected && { value: getItemKeys(filters.ageGroupSelected) }),
          },
        },
        sorting: sorting || tabConfiguration?.sorting,
        options: {
          ...tabConfiguration?.options,
          ...(tabConfiguration?.options?.isHeatmapColored && {
            isHeatmapColored: {
              ...tabConfiguration?.options?.isHeatmapColored,
              value: options.isHeatmapColored,
            },
          }),
          ...(tabConfiguration?.options?.includeCompanyAverage && {
            includeCompanyAverage: {
              ...tabConfiguration?.options?.includeCompanyAverage,
              value: options.includeCompanyAverage,
            },
          }),
          ...(tabConfiguration?.options?.includeBenchmark && {
            includeBenchmark: {
              ...tabConfiguration?.options?.includeBenchmark,
              value: options.includeBenchmark,
            },
          }),
          ...(tabConfiguration?.options?.includeTeamAverage && {
            includeTeamAverage: {
              ...tabConfiguration?.options?.includeTeamAverage,
              value: options.includeTeamAverage,
            },
          }),
          ...(tabConfiguration?.options?.includePeerReviewAverage && {
            includePeerReviewAverage: {
              ...tabConfiguration?.options?.includePeerReviewAverage,
              value: options.includePeerReviewAverage,
            },
          }),
          ...(tabConfiguration?.options?.includeSelfReviewAverage && {
            includeSelfReviewAverage: {
              ...tabConfiguration?.options?.includeSelfReviewAverage,
              value: options.includeSelfReviewAverage,
            },
          }),
        },
      };

      if (reportId && reportType) {
        dispatch(startGetCustomReports());
        if (isUpdate) {
          await reportsService.updateCustomReport(
            payload as unknown as TGetEngagementDetailsPayload,
            reportId,
            reportType,
          );
        } else {
          await reportsService.createCustomReport(
            payload as unknown as TGetEngagementDetailsPayload,
            reportId,
            reportType,
          );
        }

        const { data } = await reportsService.getCustomReports();
        dispatch(finishGetCustomReports(data.reports));
        addToast({
          title: isUpdate ? i18n._(t`Updated Successfully`) : i18n._(t`Created Successfully`),
          subtitle: isUpdate
            ? i18n._(t`Your report has been updated.`)
            : i18n._(t`Your report has been created.`),
          type: TOAST_TYPES.INFO,
        });
      }
    } catch (_error) {
      addToast({
        title: i18n._(t`Something went wrong`),
        subtitle: i18n._(t`Something went wrong while fetching the data. Please try again later.`),
        type: TOAST_TYPES.ERROR,
      });
    } finally {
      setShowSaveModal(false);
      setOnCreateLoader(false);
    }
  };
  const onChangeLoader = onCreateLoader || isLoading;
  return (
    <>
      <EngagementHeader
        isLoading={onChangeLoader}
        showSaveDropdown={showSaveDropdown}
        setShowSaveDropdown={setShowSaveDropdown}
        setValue={setValue}
        setShowSaveModal={setShowSaveModal}
        onUpdateOrCreateReport={onUpdateOrCreateReport}
        setShowDownloadDropdown={setShowDownloadDropdown}
        showDownloadDropdown={showDownloadDropdown}
        setDownloadReportType={setDownloadReportType}
      />
      <TabBody isFull={!showMenu}>{children}</TabBody>
      {showSaveModal && (
        <EngagementModal
          setShowSaveModal={setShowSaveModal}
          onUpdateOrCreateReport={onUpdateOrCreateReport}
          saveAsLoading={onChangeLoader}
          errors={errors}
          register={register}
        />
      )}
    </>
  );
};

export { HeaderIncluded };
